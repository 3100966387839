import "./bootstrap";
import Alpine from 'alpinejs'

window.Alpine = Alpine

import './plugins/clipboard-js.js';
import './plugins/alpine-scripts';
import './plugins/tables/index.js'

Alpine.start()

// Laracasts Flash Package
document.addEventListener("DOMContentLoaded", function () {
    // $('#flash-overlay-modal').modal();
    // $('div.alert').not('.alert-important').delay(3000).fadeOut(350);

    let element = document.getElementById("flash-overlay-modal");
    if (!element) {
        return;
    }
    const myModal = new window.bootstrap.Modal(element, {});
    myModal.show();
});
