import axios from "axios";

function recentOrdersReports() {
    return {
        orders: [],

        error: null,

        loading: true,

        async init() {
            try {
                const response = await axios.get("/dashboard/reports?type=recent_orders");
                this.orders = response.data.data;
            } catch (e) {
                this.error = "فشل جلب البيانات";

                console.debug(e);
            } finally {
                this.loading = false;
            }
        },
    };
}

window.appRecentOrdersReports = recentOrdersReports;