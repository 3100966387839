import { TabulatorFull as Tabulator } from "tabulator-tables";

document.addEventListener("DOMContentLoaded", function () {
    const tableElement = document.getElementById("OrderTable");

    if (!tableElement) {
        return;
    }

    const table = new Tabulator(tableElement, {
        // height:"100px",
        columns: [
            { title: "#", field: "id" },
            {
                title: "العميل",
                field: "user_name",
                sorter: "number",
                headerFilter: "input",
                headerFilterPlaceholder: "اسم العميل",
            },
            {
                title: "الخدمة",
                field: "service_name",
                maxWidth: "300px",
                headerFilter: "input",
                headerFilterPlaceholder: "اسم الخدمة",
            },
            {
                title: "مقدم الخدمة",
                field: "provider_name",
                headerFilter: "input",
                headerFilterPlaceholder: "اسم مقدم الخدمة",
            },
            { title: "السعر", field: "price.formatted" },
            {
                title: "حالة الطلب",
                field: "state",
                hozAlign: "center",
                formatter: cell => cell.getData().state_badge,
                headerFilter: "list",
                headerFilterPlaceholder: "تصفية",
                headerFilterParams: { values: { "confirmed": "مؤكد", "pending": "قيد الانتظار", "rejected": "مرفوض" } },
            },
            {
                title: "حالة الدفع",
                field: "payment_badge",
                hozAlign: "center",
                formatter: "html",
                headerSort: false,
                headerFilter: "list",
                headerFilterPlaceholder: "تصفية",
                headerFilterParams: { values: { "true": "مدفوع", "false": "غير مدفوع" } },
            },
            { title: "تاريخ الإنشاء", field: "created_at", hozAlign: "start" },
        ],

        layout: "fitDataStretch",
        autoColumns: false,

        // Localization.
        textDirection: "rtl", // Important as we use RtlCSS to switch direction.
        locale: "ar", //auto detect the current language.
        langs: {
            ar: {
                data: {
                    loading: "جاري التحميل", //data loader text
                    error: "خطأ", //data error text
                },
                groups: {
                    //copy for the auto generated item count in group header
                    item: "عنصر", //the singular  for item
                    items: "العناصر", //the plural for items
                },
                pagination: {
                    page_size: "العدد", //label for the page size select element
                    page_title: "عرض الصفحة", //tooltip text for the numeric page button, appears in front of the page number (eg. "Show Page" will result in a tool tip of "Show Page 1" on the page 1 button)
                    first: "الأول", //text for the first page button
                    first_title: "الأول", //tooltip text for the first page button
                    last: "الأخير",
                    last_title: "الأخيرة",
                    prev: "السابق",
                    prev_title: "السابقة",
                    next: "التالي",
                    next_title: "التالية",
                    all: "الكل",
                    counter: {
                        showing: "عرض",
                        of: "من",
                        rows: "",
                        pages: "صفحات",
                    },
                },
                headerFilters: {
                    default: "filter column...", //default header filter placeholder text
                    columns: {
                        name: "filter name...", //replace default header filter text for column name
                    },
                },
            },
        },


        // Persistence
        /**  We don't like persist feature so far, we will try again. @see http://tabulator.info/docs/5.1/persist */
        // persistence: {
        //     page: true,
        //     sort: true,
        //     filter: true,
        //     columns: true,
        //     group: true,
        // }, //enable table persistence

        // Pagination
        pagination: true,
        paginationMode: "remote",
        paginationSize: 15,
        paginationSizeSelector: [ 15, 25, 50, 100 ], //enable page size select element with these options
        paginationCounter: "rows", //add pagination row counter

        sortMode: "remote",
        filterMode: "remote", //send filter data to the server instead of processing locally

        ajaxRequestFunc: (url, config, params) => {
            url = ajaxURLGenerator(url, config, params);

            return axios.get(url).then(function ({ data }) {
                return {
                    data: data.data,
                    last_page: data.meta.last_page,
                    last_row: data.meta.total,
                };
            });
        },

        ajaxURL: "/dashboard/orders",
        // initialSort: [{ column: "created_at", dir: "desc" }],
    });

    table.on("tableBuilt", function () {
        console.log("tableBuilt");
    });

    table.on("rowClick", function (e, row) {
        window.location.href = "/dashboard/orders/" + row.getData().id;
    });
});

const ajaxURLGenerator = (url, config, params) => {
    // Format the sort param if it exists.
    if (params.sort && params.sort.length) {
        params.sort = params.sort
            // Convert the object to the required format by the api
            // This will convert: [{ field: "name", dir: "asc"}, {field: "age", dir: "desc" }] to "name,-age"
            .reduce(
                (all, current) =>
                    `${all}${current["dir"] === "desc" ? "-" : ""}${
                        current["field"]
                    },`,
                "",
            )
            // Remove last comma from the string.
            .replace(/,$/, "");
    } else {
        // No sort options, delete tha params.
        delete params.sort;
    }

    // Format the filter param if it exists.
    // We use `filter[field]=value` for each filter.
    if (params.filter && params.filter.length) {
        // We need to convert the array of filters to an object, and concatenate them with params object.
        params = {
            ...params,
            // This will convert the array of filters to a single objects {filter[field1]: 'value1', filter[field2]: 'value2'}
            ...params.filter.reduce(
                (all, filter) => ({
                    ...all,
                    [`filter[${filter["field"]}]`]: filter["value"],
                }),
                {},
            ),
        };

        // We don't need to add `filter` param to the query, as we will use `filter[field]`
        delete params.filter;
    }

    return `${url}?${new URLSearchParams(params).toString()}`;
};

