import axios from "axios";

function modelsCountReports() {
    return {
        data: {},

        error: null,

        loading: true,

        async init() {
            try {
                const response = await axios.get("/dashboard/reports?type=models_count");
                console.log(response.data);

                this.data = response.data;
            } catch (e) {
                this.error = "فشل جلب البيانات";

                console.debug(e);
            } finally {
                this.loading = false;
            }
        },
    };
}

window.appModelsCountReports = modelsCountReports;