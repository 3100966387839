import axios from "axios";

function changeEmailFormData() {
    return {
        newEmail: "",
        code: "",
        error: "",

        isRequestingCode: false,
        isWaitingForCodeInput: false,
        isVerifyingCode: false,

        init() {
            //
        },

        async onRequestCode(event) {
            this.error = "";
            this.isRequestingCode = true;

            if (!this.newEmail) {
                this.error = "يرجى إدخال البريد الإلكتروني الجديد";
                this.isRequestingCode = false;

                return;
            }


            try {
                await axios.post("/change-email/code", { email: this.newEmail });
                this.isWaitingForCodeInput = true;

            } catch (e) {
                this.isRequestingCode = false;

                if (e.response && e.response.status === 422) {
                    this.error = e.response.data.errors.email || e.response.data.message;

                    return;
                }

                if (e.response && e.response.status === 429) {
                    this.error = "تجاوزت الحد المسموح به من المحاولات، يرجى الانتظار قبل المحاولة مرة أخرى.";

                    return;
                }

                this.error = "حدث خطأ ما، يرجى المحاولة مرة أخرى.";
            }
        },

        async onVerifyCode(event) {
            this.isVerifyingCode = true;
            this.error = "";

            if (!this.code) {
                this.error = "يرجى إدخال رمز التحقق.";

                return;
            }

            try {
                await axios.post("/change-email/verify", { email: this.newEmail, code: this.code });

                this.showSuccessMessage();

            } catch (e) {
                this.isVerifyingCode = false;

                if (e.response && e.response.status === 422) {
                    this.error = e.response.data.errors.code || e.response.data.errors.email || e.response.data.message;

                    return;
                }

                if (e.response && e.response.status === 429) {
                    this.error = "تجاوزت الحد المسموح به من المحاولات، يرجى الانتظار قبل المحاولة مرة أخرى.";

                    return;
                }

                this.error = "حدث خطأ ما، يرجى المحاولة مرة أخرى.";
            }
        },

        showSuccessMessage() {
            window.Swal.fire({
                title: "تم تغيير البريد الإلكتروني بنجاح.",
                confirmButtonText: "تم",
                icon: "success",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    confirmButton: 'btn btn-success',
                },
                confirmButtonColor: '#155068FF',
            }).then(() => window.location.reload());
        },
    };
}

window.appChangeEmailFormData = changeEmailFormData;
