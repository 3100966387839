function iconPickerData(value) {
    return {
        iconClass: value,

        onSelect(jsonIconData) {
            this.iconClass = jsonIconData.iconClass;
        },

        init() {
            const options = {
                iconLibraries: [
                    'font-awesome.min.json'
                ],

                iconLibrariesCss: [
                    'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css'
                ],

                onSelect: this.onSelect.bind(this),
            }

            // new a('#selector');
            new UniversalIconPicker('#selector', options);
        },
    };
}

window.appIconPickerData = iconPickerData;
