function searchFormData() {
    return {
        searchTerm: null,

        init() {
            const url = new URLSearchParams(window.location.search);
            this.searchTerm = url.get('q');
        },

        /**
         * @param {Event} event
         */
        onSubmit(event) {
            const url = new URL(window.location);
            url.searchParams.set('q', this.searchTerm);
            url.searchParams.delete('page');

            window.location.href = url.toString();
        },

        /**
         * @param {Event} event
         */
        onSearch(event) {
            if (event.target.value && event.target.value.length) {
                console.log('NOT A CLEAR BUTTON')
                return;
            }

            this.searchTerm = null;

            this.$el.closest('form').submit();
        },
    };
}

window.appSearchFormData = searchFormData;
