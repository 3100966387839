function filterFormData(paramName) {
    return {
        filterTerm: null,
        paramName,

        init() {
            const url = new URLSearchParams(window.location.search);
            this.filterTerm = url.get(paramName);

            if (!this.filterTerm) {
                return;
            }

            if (!this.$refs.selectInput || !this.$refs.selectInput.choices) {
                return;
            }

            this.$refs.selectInput.choices.setChoiceByValue(this.filterTerm);
        },

        onChange(event) {
            const url = new URL(window.location);
            url.searchParams.set(this.paramName, this.filterTerm.value);
            url.searchParams.delete('page');

            window.location.href = url.toString();
        },
    };
}

window.appFilterFormData = filterFormData;
