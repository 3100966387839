import axios from "axios";

function topProvidersReports() {
    return {
        entities: [],

        error: null,

        loading: true,

        async init() {
            try {
                const response = await axios.get("/dashboard/reports?type=top_providers");
                this.entities = response.data;

            } catch (e) {
                this.error = "فشل جلب البيانات";

                console.debug(e);
            } finally {
                this.loading = false;
            }
        },
    };
}

window.appTopProvidersReports = topProvidersReports;